import React from 'react';
import { createRoot } from 'react-dom/client';
import '@/assets/styles/style.css';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import App from './App';


if (import.meta.env.NODE_ENV !== 'development') {
  //console.log('disable console.log')
  // window.console.log = (e: any) => {};
}

const container = document.getElementById('HuBOL');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
