import axios from 'axios';
import { getCurrentWebsite } from '@/utils/helpers';

const getLocalAccessToken = () => {
  return localStorage.getItem('access_token') || '';
};
const getLocalRefreshToken = () => {
  return localStorage.getItem('refresh_token') || '';
};

export const setLocalAccessToken = (accessToken: string) => {
  localStorage.setItem('access_token', accessToken);
};

const refreshToken = () => {
  return axiosApiInstance.post(`${import.meta.env.VITE_OAUTH_URL}/oauth/token`, {
    grant_type: 'refresh_token',
    refresh_token: getLocalRefreshToken(),
    client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
    scope: ''
  });
};

const axiosApiInstance = axios.create({
  baseURL: `${import.meta.env.VITE_OAUTH_URL}/api/`
});

axiosApiInstance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token && config.headers) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosApiInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await refreshToken();
          const { access_token, refresh_token, expires_in, token_type } = rs.data;
          localStorage.setItem('access_token', token_type + ' ' + access_token);
          localStorage.setItem('refresh_token', refresh_token);
          localStorage.setItem('expires_in', expires_in);
          axiosApiInstance.defaults.headers.common['Authorization'] = access_token;
          return axiosApiInstance(originalConfig);
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

export const getUser = () => {
  return axiosApiInstance.get('/user');
};

export const getBKTickets = (type: string, property = getCurrentWebsite()) => {
  return axiosApiInstance.get(
    `https://msbookingkit.azurewebsites.net/api/tickets?property=${property}&account_type=${type}`
  );
};

export const getAllBKTickets = () => {
  return axios.all([getBKTickets('activities'), getBKTickets('experience')]);
};

export const deleteBKActivity = (
  activityId: string,
  type: string,
  property = getCurrentWebsite()
) => {
  return axiosApiInstance.delete(
    `https://msbookingkit.azurewebsites.net/api/orders/${activityId}?property=${property}&account_type=${type}`
  );
};

export const sendBookingKitRequest = (type: string, property: string, data: any) => {
  return axiosApiInstance.post(
    `https://msbookingkit.azurewebsites.net/api/orders?account_type=${type}&property=${property}`,
    data
  );
};
