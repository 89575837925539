import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
// const wss_host = 'wss://hubol-socketio.azurewebsites.net';
// const wss_host = "localhost:4000";
const wss_host = import.meta.env.VITE_CART_WS_URL ?? '';
export const socket = io(wss_host, {
  // WARNING: in that case, there is no fallback to long-polling
  transports: ['websocket', "polling"], // or [ "websocket", "polling" ] (the order matters)
  reconnection: true,
  autoConnect: true
});
