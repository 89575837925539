import { ISearchForm } from '@/interfaces/bookingContext';

export const SearchParamsModel: ISearchForm = {
  property: '',
  destination: '',
  landscape: '',
  typeOfHoliday: '',
  GuestArrival: '',
  GuestDeparture: '',
  NoOfAdults: 2,
  ageOfChildren: [],
  Coupon: '',
  Child: {},
  Pets: 0,
  // status: 'to_start',
  autoSearch: false
};

export const FiltersModel = {
  roomCategories: [],
  budget: [],
  servicesVisible: [],
  isPetsAllowed: false,
  destinationFilters: []
};
