import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { socket } from './socket';

import { AppContext } from './utils/appContext';
import {
  IAppContext,
  IChannelMessageReceived,
  IFilters,
  IOrder,
  IPackageCode, IPrice,
  ISearchForm,
  ITimeEstimate
} from './interfaces/bookingContext';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import {
  getProperties
} from './utils/requests';
import { Backdrop, CircularProgress } from '@mui/material';
import { FiltersModel } from './components/utils/constants';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  addPricePropsToProperty,
  filterAvailability, g, getCurrentWebsite,
  getDataFromUrl, gEventSearchArg, website
} from './utils/helpers';
import { ICartProfiles, IProperty } from './interfaces/huWebsite';

import { useCookies } from 'react-cookie';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useGetCart } from './api/cart';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { usePostSearch } from '@/api/availability';
import ReCAPTCHA from 'react-google-recaptcha';
import CustomizedWaitingPage from '@/components/step4/modal/CustomizedWaitingPage';


declare global {
  interface Window {
    current_lang: string;
  }
}

let availabilityRequests: Promise<any>[] = [];

const Main = (props: { children: React.ReactNode }) => {

  // console.count('render Main');

  const navigate = useNavigate();

  const [language, setLanguage] = useState(
    localStorage.getItem('i18nextLng') || window.current_lang || 'en'
  );
  const [backdropIsOpen, setBackdropIsOpen] = React.useState(false);
  const [customizeWaitingIsOpen, setCustomizeWaitingIsOpen] = React.useState<boolean>(false);
  let [requestsActive, setRequestsActive] = useState<number | null>(null);
  const [minStay, setMinStay] = useState<number>(0);

  const [serverIsBusy, setServerBusy] = useState<boolean>(false);
  const [serverRequestID, setServerRequestID] = useState<number | null>(null);
  const [estimateTime, setEstimateTime] = useState<ITimeEstimate | null>(null);
  const [openings, setOpenings] = useState([]);
  const [searchForms, setSearchForms] = useState<ISearchForm[]>(() => {
    return getDataFromUrl();
  });
  const [rightDrawerState, setRightDrawerState] = useState('');
  const [drawerCart, setDrawerCart] = useState(false);
  const [drawerProfile, setDrawerProfile] = useState(false);

  const [filters, setFilters] = useState<IFilters>(FiltersModel);
  const [orderSelected, setOrderSelected] = useState<string>('');
  const [activeStep, setActiveStep] = React.useState(1);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [orderIndex, setOrderIndex] = useState<number>(0);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['hu-bol-uuid']);

  const [properties, setProperties] = useState<IProperty[]>([]);

  const [cartPending, setCartPending] = useState<string[]>([]);
  const [cartExpireAt, setCartExpireAt] = useState<Date | null>(null);
  const [userId, setUserId] = useState<string>(cookies['hu-bol-uuid'] ?? '');
  const [isConnected, setIsConnected] = useState(socket.connected);
  const { cartItems, cartValidating, cartError, cartLoading, cartMutate } = useGetCart(userId);
  const [cart, setCart] = useState<IOrder[]>([]);
  const [openModalCartExpiring, setOpenModalCartExpiring] = useState<boolean>(false);
  const [openModalDeleteItem, setOpenModalDeleteItem] = useState<boolean>(false);
  const [itemToRemove, setItemToRemove] = useState<IOrder | undefined>();
  const [mobileSubmitBtn, setMobileSubmitBtn] = useState<boolean>(false);
  const [propertySelected, setPropertySelected] = useState<string>('');
  const [notFound, setNotFound] = useState<number>(0);
  const [profile, setProfile] = useState<ICartProfiles | null>(null);
  const [profilesErrors, setProfilesErrors] = useState<any>(null);
  const [priceFeed, setPriceFeed] = useState<IPrice[]>([]);
  const [updateSearch, setUpdateSearch] = useState<boolean>(false);

  const [destinationModal, setDestinationModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const resetAvailability = () => {

    if (properties.length) {
      properties.map((property) => {
        property.prices = [];
        property.price_from_old = 0;
        property.price_from = 0;
        property.price_discount = 0;
        return property;
      });
      setProperties([...properties]);
    }

    filters.roomCategories = [];
    filters.servicesVisible = [];
    filters.budget = [];
    filters.destinationFilters = [];

    setFilters({ ...filters });
    setNotFound(0);
    setMinStay(0);
    setPropertySelected('');

  };

  const getProperty = (code: string): IProperty => {
    const property = properties.find((property) => property.code === code);
    if (!property) {
      throw new TypeError(`Property ${code} is not configured`);
    }
    return property;
  };

  const getSearchFormFilters = (): {
    destinations: string[];
    landscapes: string[];
    typeOfHoliday: string[];
  } => {

    const destinationsSet: Set<string> = new Set();
    const landscapesSet: Set<string> = new Set();
    const typeOfHolidaySet: Set<string> = new Set();

    properties.forEach((property) => {

      property.searchFormFilters.destinations.forEach((destination) =>
        destinationsSet.add(destination)
      );

      property.searchFormFilters.landscapes.forEach((landscape) =>
        landscapesSet.add(landscape)
      );

      property.searchFormFilters.typeOfHoliday.forEach((typeOfHoliday) =>
        typeOfHolidaySet.add(typeOfHoliday)
      );
    });

    return {
      destinations: Array.from(destinationsSet),
      landscapes: Array.from(landscapesSet),
      typeOfHoliday: Array.from(typeOfHolidaySet)
    };
  };

  const { destinations, landscapes, typeOfHoliday } = useMemo(() => getSearchFormFilters(), [properties]);

  const getPropertiesByDestination = (destination: string): IProperty[] => {
    return properties.filter((property) =>
      property.searchFormFilters.destinations.includes(destination)
    );
  };

  const getPropertiesByLandscape = (landscape: string): IProperty[] => {
    return properties.filter((property) =>
      property.searchFormFilters.landscapes.includes(landscape)
    );
  };

  const getPropertiesByTypeOfHolidays = (type: string): IProperty[] => {
    return properties.filter((property) => property.searchFormFilters.typeOfHoliday.includes(type));
  };

  useEffect(() => {
    if (searchForms[orderIndex].property) {
      setPropertySelected(searchForms[orderIndex].property);
    } else {
      if (properties.length === 1) {
        searchForms[orderIndex].property = website.code;
      }
    }
  }, [searchForms]);

  const getAvailability = async (xxx: string = ''): Promise<void> => {

    // @ts-ignore
    event.preventDefault();

    if (
      searchForms[orderIndex].GuestArrival === null ||
      searchForms[orderIndex].GuestDeparture === null ||
      searchForms[orderIndex].GuestArrival === '' ||
      searchForms[orderIndex].GuestDeparture === ''
    ) {
      document.getElementById('grid-daterangepicker')!.click();
      toast.warning(t('seleziona_date').toString());
    } else if (
      searchForms[orderIndex].GuestArrival === searchForms[orderIndex].GuestDeparture
    ) {
      document.getElementById('grid-daterangepicker')!.click();
      toast.warning(t('date_arrivo_partenza_uguali').toString());
    } else if (
      searchForms[orderIndex].destination === '' &&
      searchForms[orderIndex].property === '' &&
      searchForms[orderIndex].landscape === ''
    ) {
      toast.warning(t('seleziona_destinazione').toString());
      document.getElementById('destinationSearchField')!.click();
    } else if (
      searchForms[orderIndex].ageOfChildren.length > 0 &&
      searchForms[orderIndex].ageOfChildren.includes(-1)
    ) {
      toast.warning(t('selezionare_eta_dei_bambini').toString());
    } else {

      setUpdateSearch(false);

      setBackdropIsOpen(true);

      resetAvailability();

      // const tokenRecaptcha = await getTokenRecaptcha();

      // if (!tokenRecaptcha) return;

      usePostSearch(searchForms[orderIndex], userId, 'tokenRecaptcha').then((response) => {

        searchForms[orderIndex].autoSearch = false;

        setSearchForms([...searchForms]);

        g('event', 'search_v2', gEventSearchArg(searchForms[orderIndex], userId));

        if (response.data.search_id) {
          if (searchForms[orderIndex].property) {
            setPropertySelected(searchForms[orderIndex].property);
          }
          navigate(`/search/${response.data.search_id}`);
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.data?.error === 'same date') {
          document.getElementById('grid-daterangepicker')!.click();
        }
      }).finally(() => {
        setBackdropIsOpen(false);
      });


      // handleCloseSearchMobileModal();
      /*if (location.pathname !== '/search') {
        navigate('/search');
      }*/
    }


  };

  const getDepositPrice = (index: number) => {
    let sum = 0;
    sum += cart[index].TotalStay;
    cart[index].PackageCode.forEach((pack: IPackageCode) => {
      sum += pack.CalculatedPrice || 0;
    });

    if (cart[index].RateCode.endsWith('_AX')) {
      sum = 100;
    } else {
      sum *= 0.3;
    }

    return Number.parseFloat(sum.toFixed(2));
  };


  let incrementCartLength: number = 0;

  const handlerOnChannel: any = {
    'package.removed': function(value: IChannelMessageReceived) {
      const key = `${value.data.item_id} ${value.data.payload?.package_code}`;
      setCartPending((previous) => [...previous.filter((task) => task !== key)]);
    },
    'package.added': function(value: IChannelMessageReceived) {
      const key = `${value.data.item_id} ${value.data.payload?.package_code}`;
      setCartPending((previous) => [...previous.filter((task) => task !== key)]);
    },
    'reservation.removed': function(value: IChannelMessageReceived) {
      if (window.location.hash.includes(value.data.item_id)) {
        navigate('/search');
        if (value.data.message) {
          toast(value.data.message, {
            type: 'error'
          });
        }
      }
    },
    'reservation.added': function(value: IChannelMessageReceived) {
      incrementCartLength = cart.length + 1;
    }
  };

  const onEventFromChannel = (value: IChannelMessageReceived | any) => {
    if (value.data.subject) {
      toast(value?.error?.message ?? t(value.data.message), {
        type: value.status
      });
    }
    cartMutate().catch((error) => console.log(error));
    if (typeof handlerOnChannel[value.data.subject] === 'function') {
      handlerOnChannel[value.data.subject](value);
    }
  };

  const updateCart = (cartItems: IOrder[]) => {
    if (!userId) return;
    const x = cartItems.filter((data: any) => !data.isDeleted && !data.toBeDeleted)
      .map((row: any, index: number) => {
        if (!index) setCartExpireAt(new Date(row.toBeExpiredAt));
        row.payload.pay_deposit = row.pay_deposit;
        row.payload.BookerUUID = row.BookerUUID;
        row.payload.PrimaryUUID = row.PrimaryUUID;
        row.payload.points = row.points;
        row.payload.notes = row.notes;
        row.payload.discount = row.discount;
        if (row.payload.PackageCode.length > 0) {
          row.payload.PackageCode = row.payload.PackageCode.reduce((packages: IPackageCode[], currentPackages: IPackageCode) => {
            const x = packages.find(p => p.attr === currentPackages.attr);
            if (x && x.Price && currentPackages.Price) {
              x.Price += currentPackages.Price;
            } else {
              packages.push(currentPackages);
            }
            return packages;
          }, []);
        }
        return row.payload;
      });
    setCart(x);
  };

  const getDepositDays = (property: string) => {
    return properties.find(p => p.property === property)?.deposit_days || 30;
  };

  useEffect(() => {

    if (filters.isPetsAllowed !== !!searchForms[orderIndex].Pets) {
      filters.isPetsAllowed = !!searchForms[orderIndex].Pets;
      setFilters({ ...filters });
    }

  }, [searchForms]);


  const propertiesFiltered = useMemo(() => {
    return filterAvailability(filters, properties, searchForms[orderIndex]);
  }, [filters, properties, searchForms, language]);

  const roomCategories = useMemo(() => {

    const categoriesSet: Set<string> = new Set();

    const tmp: any = {
      s: 'CM',
      b: 'CM',
      t: 'TN',
      g: 'TN',
      p: 'PZ',
      h: 'PZ',
      c: 'PZ'
    };

    propertiesFiltered.filter(property => !propertySelected || property.code === propertySelected).forEach(property => {
      property.roomfeed.forEach(roomFeed => {
        if (property.prices.some((price) => price.RoomType.endsWith(roomFeed.room_id))) {

          if (roomFeed.room_id.length === 3 || roomFeed.room_id.startsWith('BG')) {
            categoriesSet.add('CM');
          } else if (roomFeed.room_id.startsWith('AP')) {
            categoriesSet.add('CA');
          } else if (roomFeed.room_id.length === 8) {
            const key = roomFeed.rooms[0][0].toLowerCase();
            categoriesSet.add(tmp[key] ?? roomFeed.room_id[0].toLowerCase());
          } else if (roomFeed.room_id.length === 5) {
            categoriesSet.add(roomFeed.room_id.substring(0, 2));
          } else {
            console.log('roomFeed.room_id', roomFeed.room_id);
          }
        }
      });
    });

    const list = Array.from(categoriesSet);

    return ['CM', 'CA', 'TN', 'PZ'].filter(code => list.includes(code));

  }, [propertySelected, propertiesFiltered]);


  useEffect(() => {
    const x = addPricePropsToProperty(properties, priceFeed);
    setProperties([...x]);
  }, [priceFeed]);


  useEffect(() => {
    if (properties.length > 0 && searchForms[orderIndex].autoSearch) {
      getAvailability('handleAutoSearch').then();
    }
  }, [properties, searchForms]);

  useEffect(() => {
    if (cartItems) {
      try {
        updateCart(cartItems);
      } catch (error: any) {
        console.error('Cart service unavailable');
        if (error.message) {
          toast(error.message, { type: 'error' });
        }
      }
    }
  }, [cartItems]);


  useEffect(() => {
    const clone = [...searchForms];
    sessionStorage.setItem('searchForms', JSON.stringify(clone));
  }, [searchForms]);

  useEffect(() => {
    // i18next.changeLanguage(language).then(() => console.log(`language changed ${language}`));
    dayjs.locale(i18next.language);
    setBackdropIsOpen(true);
    getProperties(i18next.language).then((response: AxiosResponse<IProperty[]>) => {

      response.data.map((property) => {
        property.prices = [];
        property.price_from_old = 0;
        property.price_from = 0;
        property.price_discount = 0;
        return property;
      });

      let data = response.data; // .filter(property => property.property !== 'EV');

      if (properties[0]?.prices) {
        data[0].prices = [...properties[0].prices];

        data = data.map(d => {
          const prices = properties.find(p => p.code === d.code)?.prices;
          if (prices) {
            d.prices = prices;
          }
          return d;
        });
      }

      setProperties([...data]);

    }).finally(() => setBackdropIsOpen(false));

    /*    const localeUrl = window.location.href.includes('/' + i18next.language + '/') ? window.location.href : window.location.hostname + '/' + i18next.language + '/' + window.location.host.split('.')[0] ;

        window.history.pushState('page2', 'Title', window.location.hostname);*/

    setLanguage(i18next.language);
    onChangeLanguage(i18next.language);

  }, [i18next.language]);

  useEffect(() => {
    if (userId !== cookies['hu-bol-uuid'] && cookies['hu-bol-uuid'] && cookies['hu-bol-uuid'].length === 36) {
      setUserId(cookies['hu-bol-uuid']);
    } else if (!cookies['hu-bol-uuid'] || cookies['hu-bol-uuid'].length !== 36) {
      const uuid = self.crypto.randomUUID();
      setCookie('hu-bol-uuid', uuid, { maxAge: 3600 * 24 * 30 * 12 * 10 });
    }
  }, [cookies]);


  const onChangeLanguage = useCallback((newLang: string) => {
    const { languages } = getCurrentWebsite();
    const urlObj = new URL(window.location.href); // Use URL constructor for manipulation
    const pathSegments = urlObj.pathname.split('/').filter(Boolean); // Get path segments

    // Check if the first segment is a known language code
    const hasLanguage = languages.includes(pathSegments[0]);

    let newPathname;

    if (newLang === 'en') {
      if (hasLanguage) {
        pathSegments.shift(); // Remove the language segment if it exists
      }
      // No need to add 'en' as it's the default (no segment)
    } else {
      if (hasLanguage) {
        pathSegments[0] = newLang; // Replace the existing language code
      } else {
        pathSegments.unshift(newLang); // Add the new language code
      }
    }

    newPathname = '/' + pathSegments.join('/');

    // Only pushState if the new URL is different from the current one
    if (urlObj.pathname !== newPathname) {
      urlObj.pathname = newPathname;
      // Update the URL without reloading the page
      window.history.pushState({}, '', urlObj.toString());
    }
  }, []);


  /*useEffect(() => {
    console.log('isConnected', isConnected);

  }, [isConnected]);*/

  useEffect(() => {

    function onConnect() {
      console.log('WS connected');
      setIsConnected(true);
    }

    function onDisconnect() {
      console.log('Disconnected');
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('cart_event', (ev) => onEventFromChannel(ev));

    socket.on('askForUserId', () => {
      // console.log('emit askForUserId', cookies['hu-bol-uuid']);
      socket.emit('userIdReceived', cookies['hu-bol-uuid']);
    });

    /*socket.on('send', (message: any) => {
      console.log('You received a message');
      console.log(message.data);
    });*/

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('cart_event', onEventFromChannel);
    };
  }, []);

  const getTokenRecaptcha = async () => {
    return await recaptchaRef.current.executeAsync();
  };

  const value: IAppContext = {
    propertySelected,
    notFound,
    userId,
    destinations,
    landscapes,
    typeOfHoliday,
    roomCategories,
    rightDrawerState,
    drawerCart,
    drawerProfile,
    availabilityRequests,
    requestsActive,
    language,
    searchForms,
    orders,
    filters,
    orderSelected,
    orderIndex,
    activeStep,
    minStay,
    openings,
    backdropIsOpen,
    serverIsBusy,
    serverRequestID,
    estimateTime,
    cart,
    cartPending,
    cartExpireAt,
    openModalCartExpiring,
    openModalDeleteItem,
    mobileSubmitBtn,
    itemToRemove,
    profilesErrors,
    priceFeed,
    customizeWaitingIsOpen,
    setPriceFeed,
    setCustomizeWaitingIsOpen,
    setProfilesErrors,
    setItemToRemove,
    setNotFound,
    setPropertySelected,
    setMobileSubmitBtn,
    setOpenModalCartExpiring,
    setOpenModalDeleteItem,
    setUserId,
    setCartPending,
    setCart,
    getProperty,
    setProperties,
    getPropertiesByDestination,
    getPropertiesByLandscape,
    getPropertiesByTypeOfHolidays,
    getAvailability,
    getDepositPrice,
    resetAvailability,
    setSearchForms,
    setRightDrawerState,
    setDrawerCart,
    setDrawerProfile,
    setLanguage,
    setOrders,
    setBackdropIsOpen,
    setServerRequestID,
    setServerBusy,
    setOpenings,
    setActiveStep,
    setOrderIndex,
    setFilters,
    setOrderSelected,
    properties,
    getDepositDays,
    propertiesFiltered,
    setRequestsActive,
    updateSearch,
    setUpdateSearch,
    cartMutate,
    getTokenRecaptcha,
    destinationModal,
    setDestinationModal
  };

  const recaptchaRef = React.useRef({} as ReCAPTCHA);


  return properties.length > 0 ? (
    <AppContext.Provider value={value}>
      {/*<ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA}
        size={'invisible'}
        ref={recaptchaRef}
        hl={language}
      />*/}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
        limit={1}
        closeButton={<CloseIcon />}
      />
      <Backdrop open={backdropIsOpen} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomizedWaitingPage params={{ customizeWaitingIsOpen }} />
      <main>{props.children}</main>
    </AppContext.Provider>
  ) : (
    <Backdrop open={true} style={{ zIndex: 9999 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Main;
