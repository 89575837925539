import axios from "axios";

export const logout = () => {

  const config = {
    headers: {
      Authorization: `${localStorage.getItem('access_token')}`
    }
  }

  return axios.delete(
    `https://accounts.huopenair.com/api/logout`, config
  ).then(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('expires_in');
      window.location.reload();
  }).catch((e) => {
    console.log(e)
  });
};
