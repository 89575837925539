import {useMemo} from "react";
import {endpoints, fetcher} from "@/utils/axios";
import useSWR from "swr";
import axios from "axios";
import {ISearchForm} from "@/interfaces/bookingContext";
import { website } from '@/utils/helpers';

const config = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: 2
};

export const useGetAvailability = (searchId: string | undefined, checkAvailability: boolean) => {

  const URL = `${endpoints.availability}/search/${searchId}`;

  const {data, isLoading, error, isValidating, mutate} = useSWR(URL, checkAvailability ? fetcher : null, config);

  return useMemo(
    () => ({
      pricesFeed: data,
      pricesFeedLoading: isLoading,
      pricesFeedError: error,
      pricesFeedValidating: isValidating,
      pricesFeedMutate: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );
}

export const usePostSearch = async (searchForm: ISearchForm, userId: string, token: string) => {
  searchForm.pos = website.code
  const URL = `${endpoints.availability}/search`;
  axios.defaults.headers.post['user_id'] = userId
  axios.defaults.headers.post['Grecaptcha'] = token
  return axios.post(URL, searchForm);
}
