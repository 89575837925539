import {Backdrop, Box, CircularProgress, Typography} from "@mui/material";
import Lottie, {useLottie} from "lottie-react";
import huLoadingAnimation from "@/assets/animations/huLoading.json";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {website} from "@/utils/helpers";
import waitingImg from '/assets/img/palagina-waiting.png';

const CustomizedWaitingPage = (props: any) => {

  const options = {
    animationData: huLoadingAnimation,
    loop: true
  };

  const {t} = useTranslation();
  const { View } = useLottie(options);

  return (
    <Backdrop open={ props.params.customizeWaitingIsOpen } style={{ zIndex: 999, backgroundColor: 'rgba(0,0,0,0.8)' }}>
      <Box textAlign={'center'} sx={{ width: {xs: '80%', sm: 'auto!important'} }}>
        {
          website.code !== 'VP' ? (
            <>{View}</>
          ) : (
            <img src={waitingImg} width={200} style={{marginBottom: '40px'}}></img>
          )
        }
        <Typography variant={"h4"} component={"h4"} sx={{ fontWeight: 'bold', color: '#FFF', fontSize: {xs: 24, sm: 38} }}>{t('waiting_payment_title')}</Typography>
        <Typography variant={"h4"} component={"h4"} sx={{ fontWeight: 'bold', color: '#FFF', fontSize: {xs: 18, sm: 32}, marginTop: 2 }}>{t('waiting_payment_subtitle')}</Typography>
      </Box>
    </Backdrop>
  )
};

export default CustomizedWaitingPage;
