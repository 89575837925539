import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext, IUser } from './AuthContext';
import { login, signUp } from './login';
import axios, { AxiosResponse } from 'axios';
import { logout } from './logout';
import { getUser } from './userMethods';
import { AppContext } from '@/utils/appContext';

export type AuthProviderProps = {
  // defaultAuthenticated?: boolean;
  // onLogin?: () => void;
  // onLogout?: () => void;
};

export const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(
    !!localStorage.getItem('access_token')
  );
  const [user, setUser] = useState<IUser | null>(null);
  const appContext = useContext(AppContext);

  useEffect(() => {
    !!localStorage.getItem('access_token') !== isAuthenticated &&
      setAuthenticated(!!localStorage.getItem('access_token'));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getUser().then((response: AxiosResponse<IUser>) => {
        setUser(response.data);
        appContext.setBackdropIsOpen(false);
      });
    }
  }, [isAuthenticated]);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');

  useEffect(() => {
    appContext.setBackdropIsOpen(!!code);
  }, []);

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setAuthenticated,
      signUp,
      logout,
      user,
      login
    }),
    [isAuthenticated, user]
  );

  useEffect(() => {
    if (code && state && !isAuthenticated) {
      //alert(isAuthenticated);
      // appContext.setBackdropOpened(true);

      if (state === window.localStorage.getItem('state')) {
        const params = {
          grant_type: 'authorization_code',
          client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
          redirect_uri: window.location.origin + window.location.pathname,
          code_verifier: localStorage.getItem('verifier'),
          code: code
        };

        axios
          .post(`${import.meta.env.VITE_OAUTH_URL}/oauth/token`, params)
          .then((resp) => {
            // window.opener.postMessage(resp.data);
            localStorage.removeItem('state');
            localStorage.removeItem('verifier');
            const { token_type, expires_in, access_token, refresh_token } = resp.data;
            localStorage.setItem('access_token', token_type + ' ' + access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('expires_in', expires_in);
            // window.location.reload()
            const redirectUrlAfterLogin: string | null =
              localStorage.getItem('redirect_after_login');
            if (redirectUrlAfterLogin) {
              localStorage.removeItem('redirect_after_login');
              window.location.replace(redirectUrlAfterLogin);
            } else {
              window.location.reload();
            }
            // window.close();
          })
          .catch((e) => {
            console.dir(e);
          });
      }
    }
  }, []);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
