import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(resourcesToBackend((language: string) => import(`./translations/${language}.json`)))
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    //resources,
    debug: false,
    detection: {
      order: ['htmlTag', 'localStorage', 'cookie', 'path', 'querystring', 'navigator', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    supportedLngs: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });


export default i18n;
