import React from 'react';
import { signUp } from './login';

export interface IUser {
  id: number;
  email: string;
  avatar: IUserAvatar | null;
  firstname: string;
  lastname: string;
  gender: string;
  language: string;
  nationality: string;
  birth_date: Date;
  created_at: Date;
  updated_at: Date;
  loyalty: ILoyalty;
}

export interface ILoyalty {
  loyalty: boolean,
  points: number
}

export interface IUserAvatar {
  src: string;
}

export type IAuthContext = {
  isAuthenticated: boolean;
  setAuthenticated: (isAuthenticated: boolean) => void;
  logout: () => void;
  signUp: () => void;
  user: IUser | null;
  login: (url: string) => void;
};

/* istanbul ignore next */

export const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);
