import {useMemo} from "react";
import {endpoints, fetcher} from "@/utils/axios";
import useSWR from "swr";
import {IOrder} from "@/interfaces/bookingContext";
import axios from "axios";

const config = {
  revalidateOnFocus: true,
  revalidateOnMount: true,
  revalidateOnReconnect: true,
  refreshWhenOffline: true,
  refreshWhenHidden: true,
  refreshInterval: 0
};

export const useGetCart = (userId: string) => {

  const URL = `${endpoints.cart}/carts/${userId}`;

  const {data, isLoading, error, isValidating, mutate} = useSWR(URL, fetcher, config);

  return useMemo(
    () => ({
      cartItems: data as IOrder[],
      cartLoading: isLoading,
      cartError: error,
      cartValidating: isValidating,
      cartMutate: mutate
    }),
    [data, error, isLoading, isValidating, mutate]
  );
}

export const useGetCartOrder = (userId: string, item_id: string) => {
  const URL = `${endpoints.cart}/carts/${userId}/${item_id}`;

  const {data, isLoading, error, isValidating} = useSWR(URL, fetcher);

  return useMemo(
    () => ({
      cart: data as IOrder,
      cartLoading: isLoading,
      cartError: error,
      cartValidating: isValidating,
    }),
    [data?.cart, error, isLoading, isValidating]
  );
}


export const useSaveCartItem = async (data: IOrder) => {
  const URL = `${endpoints.cart}/carts/${data.cart_id}/${data.item_id}`;

  return await axios.put(URL, data);
}

